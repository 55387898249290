import React from "react";
import Header, { HeaderVariant } from "../Header";
import GradientTitle from "../GradientTitle";

interface EventSpeakersProps {
  title?: string[];
  speakers?: any;
  speakerIds?: number[];
}

const EventSpeakers: React.FC<EventSpeakersProps> = props => {
  const { title, speakers, speakerIds } = props;

  return (
    <div className="speakers">
      <GradientTitle
        gradientPosition={2}
        className="max-w-[14rem] sm:max-w-max mx-auto"
        color="purple"
        variant={HeaderVariant.h4}
        title={title}
        lineBreak={false}
      />
      <div className="flex flex-row flex-wrap my-18 gap-8 w-full max-w-7xl mx-auto justify-center">
        {speakers?.map((speaker, index) => {
          if (speakerIds?.includes(speaker?.id)) {
            return (
              <div
                key={index}
                className="w-85 border-2 border-indigo-1 rounded-xl p-8 bg-white  text-indigo-3-dark"
              >
                <img
                  className="rounded-full border-2 border-indigo-1"
                  src={speaker?.photoSet?.medium}
                  alt=""
                  width={128}
                  height={128}
                />
                <p className="font-semibold text-lg my-4">
                  {speaker?.firstname + " " + speaker?.lastname}
                </p>
                <p className="text-base">{speaker?.title}</p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default EventSpeakers;
