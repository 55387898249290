import React from "react";
import {
  formatSubtitle,
  formatParagraphs,
  formatQuestionMarkBox
} from "../../../utils/webinars";
import Icon, { IconType } from "../../Icon";
import QuestionMarkBox from "../../QuestionMarkBox";

interface EventContentProps {
  content?: any;
}

const EventContent: React.FC<EventContentProps> = props => {
  const { content } = props;

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="relative">
          <div className="rounded-full h-18 w-18 shadow-xl flex items-center justify-center bg-white mb-3 z-10 relative">
            <Icon iconType={IconType.webinar} />
          </div>
          <div className="features-card__dotted-line absolute !top-9 full z-0 lg:w-3/4 left-10"></div>

          <p className="text-3xl font-semibold mt-13">
            {formatSubtitle(content)}
          </p>
        </div>
        <div>
          {formatParagraphs(content)?.map((item, index) => {
            return (
              <p key={index} className="mt-8">
                {item}
              </p>
            );
          })}
        </div>
      </div>
      <div className="flex flex-row flex-wrap mx-auto justify-center my-18 gap-8 w-full">
        {formatQuestionMarkBox(content)?.map((item, index) => {
          return <QuestionMarkBox key={index} title={item} />;
        })}
      </div>
    </div>
  );
};

export default EventContent;
