import { graphql } from "gatsby";
import React, { useMemo, useState } from "react";
import Layout from "../../containers/Layout";
import SEO from "../../components/Seo";
import { FormType } from "../../utils/analytics/constants";
import Section from "../../containers/Section";
import EmbeddedCta from "../../components/Blog/EmbeddedCta";
import EventSpeakers from "../../components/EventSpeakers";
import FormModal from "../../components/FormModal";
import EventContent from "../../components/Webinars/EventContent";
import SingleWebinarHero from "../../components/Webinars/SingleWebinarHero";
import JoinUs from "../../components/Webinars/JoinUs";
import { formatBreadcrumb, BreadcrumbPageType } from "../../utils/breadcrumb";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";

interface SingleWebinarProps {
  data: any;
  pageContext: any;
}

const SingleWebinar: React.FC<SingleWebinarProps> = ({ data, pageContext }) => {
  const [activeRegisterModal, setActiveRegisterModal] =
    useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const event = pageContext?.content?.event;
  const session = pageContext?.content?.session;
  const speakers = pageContext?.content?.speakers;

  const contentfulData = data?.allContentfulBizzaboWebinars?.nodes?.filter(
    ev => ev?.eventId == event?.id && event?.eventId !== null
  );

  const speakerIds = session?.speakers?.map(item => item.speakerId);

  const updatedCrumbs = useMemo(() => formatBreadcrumb(pageContext, BreadcrumbPageType.WebinarSingle), []);

  return (
    <Layout>
      <SEO
        title={contentfulData[0]?.seoSettings?.metadataTitle}
        description={contentfulData[0]?.seoSettings?.metadataDescription}
      />
      <div className="max-w-8xl mx-auto mt-48 px-6 sm:px-12 lg:px-4">
        <CustomBreadcrumb crumbs={updatedCrumbs} />
      </div>
      <SingleWebinarHero
        title={event?.name}
        description={event?.description}
        startDate={event?.startDate}
        category={event?.category}
        image={event?.headerLogoUrl}
        contentfulData={contentfulData[0]}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        setActiveRegisterModal={setActiveRegisterModal}
      />

      <Section className="my-18">
        <div className="p-18 items-center bg-gradient-to-t from-indigo-3-light to-gray-6-light rounded-tl-large rounded-b-xl">
          <EventContent content={session?.descriptionHtml} />
          <EventSpeakers
            speakers={speakers}
            speakerIds={speakerIds}
            title={["Hear from", "real pros"]}
          />
          <JoinUs
            title={event?.name}
            startDate={event?.startDate}
            category={event?.category}
            contentfulData={contentfulData[0]}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            setActiveRegisterModal={setActiveRegisterModal}
          />
        </div>
        <div className="mt-18">
          <EmbeddedCta
            fullWidth
            buttonText={"Sign Up"}
            title={"Insights to help grow profit"}
            textFieldPlaceholder={"Email address"}
            successText={
              "Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
            }
          />
        </div>
      </Section>
      {activeRegisterModal && (
        <FormModal
          setActiveModal={setActiveRegisterModal}
          title={"Register to watch on-demand"}
          formType={FormType.WEBINAR}
        />
      )}
    </Layout>
  );
};

export default SingleWebinar;

export const query = graphql`
  query BizzaboEvents {
    allContentfulBizzaboWebinars(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            url
            gatsbyImageData
          }
          robots
        }
        webinarTitle
        eventId
        registrationFormId
        eventVimeoVideo
        category
      }
    }
  }
`;
