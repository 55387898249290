import React from "react";
import DateAndTime from "../../DateAndTime";
import { Name, Category, Type } from "../../../utils/analytics/constants";
import Button, { ButtonVariant, ButtonSize } from "../../Button";
import TargetAndSeries from "../TargetAndSeries";
import { useSegment } from "../../../utils/analytics";

interface JoinUsProps {
  title?: string;
  startDate?: string;
  category?: any;
  contentfulData?: any;
  isPlaying?: boolean;
  setIsPlaying?: {
    (arg0: boolean): void;
  };
  setActiveRegisterModal?: {
    (arg0: boolean): void;
  };
}

const JoinUs: React.FC<JoinUsProps> = props => {
  const {
    title,
    startDate,
    category,
    contentfulData,
    isPlaying,
    setIsPlaying,
    setActiveRegisterModal
  } = props;

  const { helpers } = useSegment();

  return (
    <div className="justify-center text-center flex items-center flex-col">
      <p className="text-indigo-1 font-semibold text-2xl my-8">
        We can’t for you to join us
      </p>
      <p className="text-3xl font-semibold">{title}</p>
      <DateAndTime date={startDate} />
      <TargetAndSeries category={category} />
      <div className="text-center">
        <Button
          variant={ButtonVariant.primary}
          size={ButtonSize.large}
          className="mt-8 mx-auto"
          text={
            contentfulData && contentfulData[0]?.eventVimeoVideo ? "Watch On-demand" : "Register"
          }
          onClick={() => {
            contentfulData && contentfulData[0]?.eventVimeoVideo
              ? setIsPlaying && setIsPlaying(true)
              : setActiveRegisterModal && setActiveRegisterModal(true);
            helpers.delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: "Register",
              page_url: location.href,
              destination: "",
              location: ""
            });
          }}
        />
      </div>
    </div>
  );
};

export default JoinUs;
