import React from "react";
import ReactPlayer from "react-player";

interface VimeoVideoProps {
  url?: string;
  isPlaying?: boolean;
}

const VimeoVideo: React.FC<VimeoVideoProps> = props => {
  const { url, isPlaying } = props;

  return (
    <div className="bg-white p-10 pb-16 flex flex-col items-center rounded-t-2xl h-full shadow">
    <p className="text-3xl mb-8 bg-gradient-to-r inline-block font-semibold text-transparent from-purple-1 to-blue-1 bg-clip-text">
      Watch the replay
    </p>
    <ReactPlayer
      width={580}
      height={330}
      url={url}
      playing={isPlaying}
      controls
      loop
    />
  </div>
  );
};

export default VimeoVideo;
