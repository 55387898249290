import React from "react";
import Icon, { IconType } from "../Icon";

interface QuestionMarkBoxProps {
  title?: any;
}

const QuestionMarkBox: React.FC<QuestionMarkBoxProps> = props => {
  const { title } = props;

  return (
    <div className="border-2 w-135 border-indigo-1 p-8 bg-white rounded-xl">
      <Icon iconType={IconType.questionMark} />
      <p className="mt-6 text-indigo-3-dark font-semibold">{title}</p>
    </div>
  );
};

export default QuestionMarkBox;
