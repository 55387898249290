import React from "react";
import { Name, Category, Type } from "../../../utils/analytics/constants";
import Button, { ButtonVariant, ButtonSize } from "../../Button";
import DateAndTime from "../../DateAndTime";
import Header, { HeaderVariant } from "../../Header";
import Paragraph from "../../Paragraph";
import TargetAndSeries from "../TargetAndSeries";
import VimeoVideo from "../VimeoVideo";
import { useSegment } from "../../../utils/analytics";

interface SingleWebinarHeroProps {
  title?: string;
  startDate?: string;
  category?: any;
  description?: string;
  image?: string;
  contentfulData?: any;
  isPlaying?: boolean;
  setIsPlaying?: {
    (arg0: boolean): void;
  };
  setActiveRegisterModal?: {
    (arg0: boolean): void;
  };
}

const SingleWebinarHero: React.FC<SingleWebinarHeroProps> = props => {
  const {
    title,
    startDate,
    category,
    description,
    image,
    contentfulData,
    setIsPlaying,
    isPlaying,
    setActiveRegisterModal
  } = props;

  const { helpers } = useSegment();

  return (
    <div className="md:mb-22 bg-gradient-to-t from-indigo-3-light">
      <div className="pt-10 grid grid-cols-1 lg:grid-cols-2 items-center max-w-8xl px-6 sm:px-12 lg:px-4 mx-auto relative">
        <div className="pr-12 md:pb-22">
          <Header variant={HeaderVariant.h1}>
            <span className="block text-4xl font-semibold mb-6 md:font-bold md:mb-8 md:text-5xl">
              {title}
            </span>
          </Header>
          <DateAndTime date={startDate} />
          <TargetAndSeries category={category} />

          <Paragraph className="text-xl mx-auto font-medium text-gray-3-dark my-8">
            {description}
          </Paragraph>
          <Button
            id={""}
            variant={ButtonVariant.primary}
            size={ButtonSize.large}
            text={
              contentfulData?.eventVimeoVideo ? "Watch On-demand" : "Register"
            }
            onClick={() => {
              contentfulData?.eventVimeoVideo
                ? setIsPlaying && setIsPlaying(true)
                : setActiveRegisterModal && setActiveRegisterModal(true);
              helpers.delayTrack(Name.Cta, {
                category: Category.ButtonClick,
                type: Type.Button,
                text: "Register",
                page_url: location.href,
                destination: "",
                location: ""
              });
            }}
          />
        </div>
        <div className="z-20 h-full flex items-center">
          {contentfulData?.eventVimeoVideo ? (
            <VimeoVideo
              url={contentfulData?.eventVimeoVideo}
              isPlaying={isPlaying}
            />
          ) : (
            <img src={image} alt="" />
          )}
        </div>
        <svg
          className="absolute right-[-40px] top-0 h-full"
          fill="none"
          height="616"
          viewBox="0 0 593 616"
          width="593"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            stroke="#6366f1"
            stroke-dasharray="7.55 11.33"
            stroke-linecap="round"
            stroke-width="1.88854"
          >
            <rect
              height="1184.11"
              rx="592.056"
              width="1184.11"
              x=".944268"
              y=".944268"
            />
            <rect
              height="861.172"
              rx="430.586"
              width="861.172"
              x="162.414"
              y="162.414"
            />
            <rect
              height="559.006"
              rx="279.503"
              width="559.006"
              x="313.497"
              y="313.497"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default SingleWebinarHero;
